import { Description, Field, Input, Label } from '@headlessuiv2/react'
import classnames from 'classnames'
import * as React from 'react'
import type { InputProps } from '@headlessuiv2/react'

export const inputClassName = classnames(
  'w-full rounded px-2 py-2 text-sm/6 shadow-subtle outline-none ring-1 ring-brand-medium',
  'placeholder:text-brand-medium',
  // Hover ring color, but not when field is focused or invalid
  '[&[data-hover]:not([data-focus]):not([data-invalid])]:ring-brand-darkest',
  'data-[focus]:ring-brand-utility',
  'data-[invalid]:ring-callout-red-90',
  'data-[disabled]:cursor-not-allowed data-[disabled]:bg-brand-lightest',
  'transition-[opacity,color,background-color,box-shadow,text-decoration-color,fill,stroke] duration-100 ease-in-out'
)

type Props = {
  name?: string
  label?: string
  error?: string

  leadingIcon?: React.ComponentType<any>
  trailingIcon?: React.ComponentType<any>

  leadingIconProps?: React.ComponentPropsWithoutRef<'svg'>
  trailingIconProps?: React.ComponentPropsWithoutRef<'svg'>
} & InputProps

export default function ({
  label,
  error,
  disabled,

  leadingIcon: LeadingIcon,
  trailingIcon: TrailingIcon,
  leadingIconProps,
  trailingIconProps,

  className: extraClassName,
  ...inputProps
}: Props) {
  return (
    <Field disabled={disabled} className="flex w-full flex-col gap-y-1.5">
      {label && <Label className="text-sm font-bold text-brand-darkest">{label}</Label>}

      <div className="relative">
        {LeadingIcon && (
          <div
            className={classnames('absolute inset-y-0 left-3 flex items-center justify-center', {
              'pointer-events-none': !leadingIconProps?.onClick
            })}>
            <LeadingIcon className="size-5 text-brand-darkest" {...leadingIconProps} aria-hidden="true" />
          </div>
        )}

        <Input
          className={classnames(inputClassName, extraClassName, {
            'pl-9': !!LeadingIcon,
            'pr-9': !!TrailingIcon
          })}
          {...(error ? { 'data-invalid': true } : undefined)}
          {...inputProps}
        />

        {TrailingIcon && (
          <div
            className={classnames('absolute inset-y-0 right-3 flex items-center justify-center', {
              'pointer-events-none': !trailingIconProps?.onClick
            })}>
            <TrailingIcon className="size-5 text-brand-darkest" {...trailingIconProps} aria-hidden="true" />
          </div>
        )}
      </div>

      {error && <Description className="text-sm text-callout-red-90">{error}</Description>}
    </Field>
  )
}
